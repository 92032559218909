import React from "react";
import gsap from "gsap/gsap-core";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Card3D, Card3DContainer, Card3DContent, Card3DHeader } from "../components/site/Card/Card3D";
import Layout from "../components/site/layout";
import SEO from "../components/site/seo";
import { isBrowser } from "../services/helper";
import Button from "../components/site/Button/button";

const descriptionMaintenance =
  "Les stages sont en cours de rédactions. Venez découvrir prochainemenet les stages lors des vacances scolaires ! Stages à cheval pour tous niveaux";

export default function Stages() {
  let card1 = null;
  let card2 = null;

  React.useEffect(() => {
    if (isBrowser()) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.registerPlugin(ScrollToPlugin);
    }

    const endAnimation = { opacity: 1, duration: 0.6, ease: "Circ.easeIn" };
    if (card1) {
      gsap.fromTo(
        card1,
        { opacity: 0, x: 1000, y: -1000 },
        { ...endAnimation, scrollTrigger: card1, x: 0, y: 0, duration: 0.5 }
      );
    }
    if (card2) {
      gsap.fromTo(
        card2,
        { opacity: 0, x: -1000, y: -1000 },
        { ...endAnimation, scrollTrigger: card2, x: 0, y: 0, duration: 0.5 }
      );
    }
  }, []);

  return (
    <Layout currentPage="stages">
      <SEO
        title="Stages cheval"
        description="Stages équitation - découverte équitation pendant les vacances scolaires. 
        Stage toussaint - vacances scolaire.
        Venez pour découvrir l'équitation ou pour passer vos galops ! Le centre équestre est situé à Annecy - Epagny, en Haute Savoie. 
        Pendant 2 semaines, matin ou après midi, perfectionner votre niveau à cheval ou venez apprendre. 
         pour vos enfants ou même pour adultes
         C'est une superbe activité pour enfants de tous ages. Les adultes peuvent également pratiquer : pour faire du cheval il n'y a pas d'âge !"
      />

      {/*<h1 style={{height:"1px",paddingTop:"1px", overflow:"hidden"}}>🎅🎄🎁 Inscriptions stage équitation - activités vacances enfants🎁🎄🎅</h1>*/}

      <h1>Inscriptions stage toussaint</h1>
      <div className="container">
        Pour les inscriptions et le règlement merci de passer directement au club.{" "}
        <div>
          Pour connaitre les disponibilités, vous pouvez également venir au club, ou me contacter sur mon portable :{" "}
          <a href="tel:+33620526418">06.20.52.64.18</a>
        </div>
      </div>

      <div className="pt-5" style={{textAlign:"center"}}>
        <h1>Planning</h1>
      </div>
      <div className="container-fluid" >
        <div className="container text-center mt-2">Tarif : 210€ la semaine</div>
        <Card3DContainer id="perspectiveCards">
          <Card3D fixedHeight={false}>
            <Card3DHeader>
              <h3 className="pt-3 pb-2">Du 25 au 29 octobre</h3>
            </Card3DHeader>
            <Card3DContent>
              <div className="pt-3 pb-3">
                <span><b>De 9h à 12h</b> : Galop 3 &#8594; 4</span>
              </div>
              <div className="pb-3">
                <b>De 14h à 17h</b> : Débutants 
              </div>
            </Card3DContent>
          </Card3D>
          <Card3D fixedHeight={false}>
            <Card3DHeader>
            <h3 className="pt-3 pb-2">Du 01 au 05 novembre</h3>
            </Card3DHeader>
            <Card3DContent>
              <div className="pt-3 pb-3">
                <b>De 9h à 12h</b> : Galop 1 &#8594; 2
              </div>
              <div className="pb-3">
                <b>De 14h à 17h</b> : Débutants
              </div>
            </Card3DContent>
          </Card3D>
          </Card3DContainer>
      </div>


      <h1 className="pt-5">Venez nombreuses/nombreux &#128578;</h1>
    </Layout>
  );
}
